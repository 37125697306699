<template>
    <div class="stores-links">
        <h1>A note-sharing app that distills the <br class="d-none d-md-block">world’s most powerful ideas.</h1>
        <!-- <p>Get the app to read lorem iorem ipsum dolor sit amet, consectetur<br class="d-none d-md-block">adipiscing elit, sed do eiusmod tempor incididunt ut labor.</p> -->
        <div class="row no-gutters justify-content-center">
            <div class="col-auto">
                <a href="#" class="apple">
                    <img src="@assets/img/icons/apple-store.svg" height="50" alt="Apple Store">
                </a>
            </div>
            <!-- <div class="col-auto">
                <a href="#" class="google">
                    <img src="@assets/img/icons/play-store.svg" height="50" alt="Play Store">
                </a>
            </div> -->
        </div>
    </div>
</template>

<script>
export default {
    name: "StoresLinks"
}
</script>

<style lang="scss" scoped>
.stores-links {
    padding: 130px 0;
    text-align: center;
    background-image: url("~@assets/img/bg.jpg");
    background-position: left;
    background-repeat: no-repeat;
    background-size: 130%;
    opacity: 0.7;

    @media(max-width: $md) {
        padding: 40px 20px;
        background-size: 250%;
    }

    h1 {
        font-size: 25px;
        color: $primary-color;
        line-height: 27px;
        margin-bottom: 30px;

        @media(max-width: $md) {
            font-size: 26px;
            line-height: 31px;
            margin-bottom: 25px;
        }
    }

    p {
        color: rgba(255, 255, 255, .5);
        font-size: 19px;
        line-height: 23px;
        margin-bottom: 100px;

        @media(max-width: $md) {
            font-size: 16px;
            line-height: 19px;
            margin-bottom: 45px;
        }
    }

    img {
        @media(max-width: $md) {
            height: 43px;
        }
    }

    a {
        display: block;
    }

    .apple {
        margin-right: 10px;

        @media(max-width: 350px) {
            margin-right: 0;
            margin-bottom: 10px;
        }
    }
}
</style>
